import { createContext, useMemo, useEffect, useCallback } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/browser';
import { useAuth } from '@core/auth';
import { ENV } from '@core/config';

type Page =
  | 'SIGN_UP'
  | 'SIGN_IN'
  | 'RESET_PASSWORD'
  | 'COMPANY'
  | 'COMPANY_JOB_APPLY'
  | 'APP_SETTINGS'
  | 'APP_HOME'
  | 'APP_JOBS'
  | 'APP_JOBS_NEW'
  | 'APP_JOBS_EDIT'
  | 'APP_JOB_DETAIL'
  | 'APP_JOB_PREVIEW'
  | 'APP_APPLICANT_DETAIL'
  | 'ERROR_INTERNAL'
  | 'ERROR_500'
  | 'ERROR_404'
  | 'LOGOUT'
  | 'ONBOARDING'
  | 'JOIN_WORKSPACE'
  | 'WORKSPACE_APPLICATIONS'
  | 'WORKSPACE_SETTINGS'
  | 'SETTINGS_ACCOUNT'
  | 'SETTINGS_WORKSPACE'
  | 'SETTINGS_MEMBERS'
  | 'SETTINGS_BILLING'
  | 'FORMS_LIST'
  | 'FORM_DETAIL'
  | 'FORM_APPLY';

type AnalyticsProps = {
  trackPage: (page: Page) => void;
  trackShareBowld: (source: 'header' | 'empty') => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const Context = createContext<AnalyticsProps>(undefined!);

type Props = {
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children }: Props) => {
  const { initialized, user } = useAuth();

  const analytics = useMemo(
    () =>
      AnalyticsBrowser.load({
        writeKey: ENV.SEGMENT_WRITE_KEY,
      }),
    []
  );

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (user) {
      const payload = {
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
      };
      analytics.identify(user.uid, payload);
      Sentry.setUser(payload);
    } else {
      Sentry.setUser(null);
    }
  }, [analytics, initialized, user]);

  const trackPage = useCallback(
    (page: Page) => {
      analytics.page(page);
    },
    [analytics]
  );

  const trackShareBowld = useCallback(
    (source: 'header' | 'empty') => {
      analytics.track('Copied Bowld URL', {
        source,
      });
    },
    [analytics]
  );

  const memoizedValue = useMemo(
    () => ({
      trackPage,
      trackShareBowld,
    }),
    [trackPage, trackShareBowld]
  );

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>;
};
