import { ENV } from '@core/config';

export const ROUTES = {
  landing: 'https://www.bowld.ai',
  privacyPolicy: 'https://www.bowld.ai/privacy-policy',
  termsOfUse: 'https://www.bowld.ai/privacy-policy',
  termsApplicants: 'https://www.bowld.ai/terms',
  root: '/',
  signIn: '/login',
  signUp: '/sign-up',
  logout: '/logout',
  onboarding: '/onboarding',
  resetPassword: '/reset-password',
  workspace: {
    root: '/w',
    rootId: (id: string) => `/w/${id}`,
    form: (id: string, formId: string) => `/w/${id}/forms/${formId}`,
    settings: {
      root: (workspaceId: string) => `/w/${workspaceId}/settings`,
      account: (workspaceId: string) => `/w/${workspaceId}/settings/account`,
      workspace: (workspaceId: string) =>
        `/w/${workspaceId}/settings/workspace`,
      members: (workspaceId: string) => `/w/${workspaceId}/settings/members`,
      billing: (workspaceId: string) => `/w/${workspaceId}/settings/billing`,
      manageBilling: (workspaceId: string) =>
        `/w/${workspaceId}/settings/manage-billing`,
    },
    forms: {
      list: (workspaceId: string) => `/w/${workspaceId}/forms`,
    },
  },
  forms: {
    shareUrl: (id: string, locale: string) => `${ENV.URL}/${locale}/${id}`,
    id: (id: string, preview?: boolean) =>
      `/${id}${preview ? '?preview=true' : ''}`,
  },
};
