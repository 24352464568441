import useSWR from 'swr';
import { UserFull, User } from '@core/@types/users';
import axios, { fetcher } from '@core/utils/axios';

export const useGetMe = (shouldPerform: boolean) => {
  const { data, error, isLoading, mutate } = useSWR<UserFull>(
    shouldPerform ? `/users/me` : null,
    fetcher
  );

  return {
    data,
    mutate,
    isLoading,
    error: !data && error ? error : undefined,
  };
};

export const updateMe = async (
  firstName?: string,
  lastName?: string,
  photoUrl?: string
): Promise<User> => {
  const response = await axios.post('/users/me', {
    firstName,
    lastName,
    photoUrl,
  });
  return response.data;
};

export const sendAuthEmail = async (email: string) => {
  const response = await axios.post('/users/auth-email', {
    email,
  });
  return response.data;
};
