import 'simplebar/src/simplebar.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { NextIntlClientProvider } from 'next-intl';
import { CacheProvider, EmotionCache } from '@emotion/react';
import Head from 'next/head';
import { SWRConfig } from 'swr';
import { AppProps } from 'next/app';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Analytics } from '@vercel/analytics/react';
import createEmotionCache from '@core/utils/createEmotionCache';
import ThemeProvider from '@core/ui/theme';
import { StyledChart } from '@core/ui/components/chart';
import ProgressBar from '@core/ui/components/progress-bar';
import SnackbarProvider from '@core/ui/components/snackbar';
import { ThemeSettings, SettingsProvider } from '@core/ui/components/settings';
import { AuthProvider } from '@core/auth';
import { AnalyticsProvider } from '@core/analytics';
import { IntercomProvider } from '@core/intercom';
import { CurrentWorkspaceProvider } from '@features/workspaces/provider';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <NextIntlClientProvider messages={pageProps.messages}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <CurrentWorkspaceProvider>
              <IntercomProvider>
                <AnalyticsProvider>
                  <SettingsProvider>
                    <ThemeProvider>
                      <ThemeSettings>
                        <SWRConfig>
                          <SnackbarProvider>
                            <StyledChart />
                            <ProgressBar />
                            <Component {...pageProps} />
                            <Analytics />
                          </SnackbarProvider>
                        </SWRConfig>
                      </ThemeSettings>
                    </ThemeProvider>
                  </SettingsProvider>
                </AnalyticsProvider>
              </IntercomProvider>
            </CurrentWorkspaceProvider>
          </AuthProvider>
        </LocalizationProvider>
      </NextIntlClientProvider>
    </CacheProvider>
  );
}

export default MyApp;
