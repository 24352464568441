import { useContext } from 'react';
import { CurrentWorkspaceContext } from './provider';

export const useCurrentWorkspace = () => {
  const context = useContext(CurrentWorkspaceContext);

  if (!context)
    throw new Error(
      'useCurrentWorkspace context must be used inside CurrentWorkspaceContext'
    );

  return context;
};
