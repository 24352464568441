import useSWR from 'swr';
import {
  CreateWorkspace,
  CreateWorkspaceResponse,
  GetWorkspaceBillingResponse,
  GetWorkspaceMembersResponse,
  GetWorkspaceResponse,
  UpdateWorkspace,
  UpdateWorkspaceResponse,
} from '@core/@types/workspaces';
import axios, { fetcher } from '@core/utils/axios';

export const createWorkspace = async (
  workspace: CreateWorkspace
): Promise<CreateWorkspaceResponse> => {
  const response = await axios.post(`/workspaces`, workspace);
  return response.data;
};

export const getWorkspace = async (
  workspaceId: string
): Promise<GetWorkspaceResponse> => {
  const response = await axios.get(`/workspaces/${workspaceId}`);
  return response.data;
};

export const updateWorkspace = async (
  id: string,
  workspace: UpdateWorkspace
): Promise<UpdateWorkspaceResponse> => {
  const response = await axios.post(`/workspaces/${id}`, workspace);
  return response.data;
};

export const useGetWorkspaceMembers = (id: string) => {
  const { data, error, isLoading, mutate } =
    useSWR<GetWorkspaceMembersResponse>(`/workspaces/${id}/members`, fetcher);

  return {
    members: data?.workspaceMembers,
    isLoading,
    error: !data?.workspaceMembers && error ? error : undefined,
    mutate,
  };
};

export const inviteMember = async (
  workspaceId: string,
  email: string
): Promise<UpdateWorkspaceResponse> => {
  const response = await axios.post(`/workspaces/${workspaceId}/members`, {
    email,
  });
  return response.data;
};

export const deleteMember = async (
  workspaceMemberId: string
): Promise<UpdateWorkspaceResponse> => {
  const response = await axios.delete(
    `/workspaces/members/${workspaceMemberId}`
  );
  return response.data;
};

export const useGetWorkspaceBilling = (id: string) => {
  const { data, error, isLoading, mutate } =
    useSWR<GetWorkspaceBillingResponse>(`/workspaces/${id}/billing`, fetcher);

  return {
    data,
    isLoading,
    error: !data && error ? error : undefined,
    mutate,
  };
};
