import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '@core/auth';
import { ROUTES } from '@core/config';
import { UserWorkspace, Workspace } from '@core/@types/workspaces';
import { ContextState } from './types';
import { getWorkspace } from '@core/services/workspaces';

export const CurrentWorkspaceContext = createContext<ContextState | null>(null);

type ProviderProps = {
  children: React.ReactNode;
};

export function CurrentWorkspaceProvider({ children }: ProviderProps) {
  const { initialized: userInitialized, user } = useAuth();
  const { query, replace } = useRouter();

  const [userWorkspace, setUserWorkspace] = useState<
    UserWorkspace | undefined
  >();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const perform = async () => {
      if (!userInitialized) {
        return;
      }

      if (!user) {
        setInitialized(true);
        return;
      }

      const workspaceId = query.workspaceId as string;
      if (!workspaceId) {
        setInitialized(true);
        return;
      }

      let uw = user.workspaces.find((w) => w.workspace.id === workspaceId);
      if (!uw) {
        try {
          const response = await getWorkspace(workspaceId);
          uw = {
            role: 'owner',
            ...response,
          };
        } catch (err) {
          replace(ROUTES.workspace.root);
          return;
        }
      }

      setUserWorkspace(uw);
      setInitialized(true);
    };
    perform();
  }, [user, userInitialized, query, replace]);

  const memoizedValue = useMemo(
    () => ({
      initialized,
      userWorkspace: userWorkspace as UserWorkspace,
      workspace: userWorkspace?.workspace as Workspace,
    }),
    [initialized, userWorkspace]
  );

  return (
    <CurrentWorkspaceContext.Provider value={memoizedValue}>
      {children}
    </CurrentWorkspaceContext.Provider>
  );
}
